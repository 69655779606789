export enum YesNoNotAvailableEnum {
    NotAvailable = 'Not Available',
    No = 'No',
    Yes = 'Yes',
}

export enum YesNoEnum {
    Yes = 1,
    No = 0,
}

export enum PricingType {
    Fixed = 'Fixed',
    UnitwisePrice = 'Unit wise Price',
    UnitRange = 'Unit Range',
    CNFValueRange = 'C&F Value Range',
    CallForPrice = 'Call For Price'
}

export enum Antibiotic {
    Tetracycline = 'Tetracycline',
    Sulfonamide = 'Sulfonamide',
    Macrolide = 'Macrolide',
    Betalactam = 'Beta-lactam',
    Quinolone = 'Quinolone'
}

export enum Mycotoxin {
    AflatoxinBG = 'Aflatoxin B/G'
}

export enum ScintigraphyTestType {
    boneScan = 'Bone Scan',
    brain = 'Brain Perfusion SPECT',
    cholescintigraphy = 'Cholescintigraphy',
    gastric = 'Gastric Emptying Time',
    ventilation = 'Ventilation/Perfusion Scan',
    lymphoscintigraphy = 'Lymphoscintigraphy',
    meckelScan = 'Meckel’s Scan/GI bleeding Study',
    parathyroidScan = 'Parathyroid Scan',
    parotidScan = 'Parotid Scan',
    liverScan = 'Radiolabeled RBC Liver Scan',
    // renalScan = 'Renal scan Planar / SPECT',
    renalScan = 'DMSA Scan / Renal scan Planar / SPECT',
    renogram = 'Renogram(Dynamic)',
    captopril = 'Renogram(Dynamic) with Captopril',
    nodeScan = 'Sentinel Lymph Node Scan',
    lungScan = 'Lung Perfusion Scan'
}

export enum ScintigraphyRadioEnumType {
    No = 0,
    Yes = 1,
    "Not Available" = 2,
    "Available" = 3,
    "Not Applicable" = 4,
    "Applicable" = 5,
    "Normal" = 6,
    "Enlarged" = 7,
    "Negative" = 8,
    "Positive" = 9,
    "Seen" = 10,
    "Not Seen" = 11,
    "Dilated" = 11,
    "Not Dilated" = 12,
    "Abnormal" = 13,
    "Mild, Easily reversible" = 14,
    "Moderate, reversible with effort" = 15,
    "Moderate to severe, minimally reversible or irreversible" = 16,
    "Severe, irreversible" = 17,
    "None, No Dermatofibrosclerosis" = 18,
    "None to minimal, No Dermatofibrosclerosis" = 19,
    "Moderate with Dermatofibrosclerosis" = 20,
    "Severe with advanced Dermatofibrosclerosis" = 21,
    // 22
    "None to occasional" = 23,
    "<4 time/ year" = 24,
    "≥ 4 time / year" = 25,
    "Occasional and /or moderate" = 27,
    "Frequent and significant" = 28,
    "Constant and severe" = 29,
    "Good with minimal / occasional limitation" = 30,
    "Fair with moderate limitation" = 31,
    "Poor with significant" = 32,
    "Bed with severe limitation" = 33,
    "None" = 34,
    "Present in<half of each limb" = 35,
    "Present in > half of each limb" = 36,
    "Present" = 37,
    "Poor or no visualization" = 38,
    "Good" = 39,
    // 40
    "Poor" = 41,
    // 42
    // 43
    "Poor to non-visualized" = 44,
    "Non visualization" = 45,
    "Decreased" = 46,
    "Partial Clearance" = 47,
    "No clearance" = 48,
    "Oral Contraceptive Pill" = 49,
    "Steroid" = 50,
    "Low" = 51,
    "Intermediate" = 52,
    "High" = 53,
    "Other" = 54,
    "Remark" = 55,
    "Male" = 56,
    "Female" = 57,
}

export enum AdditionalParameterColumnMain {
    AIT = 'AIT',
    Angel = 'Angel',
    AutoBill = 'Auto Bill',
    BatchNo = 'Batch No/lot',
    BillEditable = 'Bill Editable',
    CalibrationItem = 'Calibration Item',
    CalibrationDate = 'Calibration Date',
    ChankofSample = 'TLD',
    ContainerQuantity = 'ContainerQuantity',
    Currency = 'Currency',
    DNAService = 'DNA Service',
    Dose = 'Dose',
    ExchangeRate = 'Exchange Rate',
    ExpertAllowance = 'Expert Allowance',
    FeeDependonTypeofWork = 'Fee Depend on Type of Work',
    ImportExportMiscRelated = 'Import Export Misc Related',
    IncludeVATAIT='Include VAT AIT',
    InitialActivity = 'Initial Activity',
    InitialActivityDate = 'Initial Activity Date',
    IsPaymentEditable = 'Is Payment Editable',
    Manufacturer = 'Manufacturer',
    Method = 'Method',
    ModeofOperation = 'Mode of Operation',
    ModelType = 'Model/Type',
    NoofBox = 'No. of Box',
    NoofRequiredPerson = 'No. of Required/Given Person',
    NoofSample = 'No. of Sample',
    Offside = 'Offside',
    Onside = 'Onside',
    Parameter = 'Parameter',
    PlantDisease = 'Plant Disease',
    Prepaid = 'Prepaid',
    Price = 'Price',
    ProbeID = 'Probe ID',
    ProbeType = 'Probe Type',
    ProductName = 'Product Name',
    Quantity = 'Quantity',
    Radionucledis = 'Radionucledis',
    RangeFrequency = 'Range/Frequency',
    ReliefPurpose = 'purpose of relief',
    Remarks = 'Remarks',
    SampleDescription = 'Sample Description',
    SampleName = 'Sample Name',
    SampleParameterAddFecilities = 'SampleParameterAddFecilities',
    SerialNo = 'Serial No.',
    TLDProcessing = 'TLD Processing',
    TissueBanking = 'Tissue Banking',
    TypeofRadionuclides = 'Type of Radionuclides',
    UnitQuantity = 'Quantity(Kilogray (kGy))',
    QuantitywithDose = 'Quantity with Dose',
    Uncertainty = 'Uncertainty',
    VAT = 'VAT',
    Voltage = 'Voltage',
    VolumnNo = 'Volumn No',
    WellDepth = 'Well Depth',
    Weight = 'Weight',
    WeekendHolidayAfterOfficeHour = 'Weekend/Holiday/After Office Hour',
}

export enum AdditionalParameterColumn {
    CalibrationItem = 'Calibration Item',
    ModelType = 'Model/Type',
    Manufacturer = 'Manufacturer',
    RangeFrequency = 'Range/Frequency',
    Voltage = 'Voltage',
    ModeofOperation = 'Mode of Operation',
    Dose = 'Dose',
    ProbeType = 'Probe Type',
    ProbeID = 'Probe ID',
    Angel = 'Angel',
    SerialNo = 'Serial No.',
    NoofRequiredPerson = 'No. of Required/Given Person',
    NoofSample = 'No. of Sample',
    Remarks = 'Remarks',
    Method = 'Method',
    NoofBox = 'No. of Box',
    FeeDependonTypeofWork = 'Fee Depend on Type of Work',
    Quantity = 'Quantity',
    UnitQuantity = 'Quantity(Kilogray (kGy))',
    ContainerQuantity = 'ContainerQuantity',
    Currency = 'Currency',
    ProductName = 'Product Name',
    CalibrationDate = 'Calibration Date',
    BatchNo = 'Batch No/lot',
    WellDepth = 'Well Depth',
    Weight = 'Weight',
    SampleName = 'Sample Name',
    VolumnNo = 'Volumn No',
    TypeofRadionuclides = 'Type of Radionuclides',
    InitialActivity = 'Initial Activity',
    InitialActivityDate = 'Initial Activity Date',
    Parameter = 'Parameter',
    Antibiotic = 'Antibiotic',
    Mycotoxin = 'Mycotoxin',
    Volumn = 'Volumn',
}



export enum NoYesEnum {
    No = 0,
    Yes = 1,
}

export enum RegistrationType {
    Individual = "INDIVIDUAL",
    'Institute/Organization' = "INSTITUTE/ORGANIZATION",
}
